body {
  background: rgb(255, 242, 227) !important;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10vh;
  display: inline-block;
}

@media (min-width: 992px) {
  .nav-item {
    margin-left: 5em;
    margin-right: 5em;
  }
}

.rnc__notification-item--success {
  background-color: var(--default);
  border-left: 8px solid var(--default-dark);
}

.rnc__notification-item--success .rnc__notification-timer {
  background-color: var(--default);
  border-left: 8px solid var(--default-dark);
}
.rnc__notification-timer-filler {
  background-color: var(--default);
}
.rnc__notification-close-mark {
  background-color: var(--default);
}

.rnc__notification-item--success .rnc__notification-timer {
  background-color: white;
}


.rnc__notification-item--danger {
  background-color: var(--danger-dark);
  border-left: 8px solid var(--danger-dark);
}

.rnc__notification-item--danger .rnc__notification-timer {
  background-color: var(--danger);
  border-left: 8px solid var(--danger-dark);
}

.rnc__notification-timer-filler {
  background-color: white;
}

.rnc__notification-close-mark {
  background-color: white;
}

.rnc__notification-item--danger .rnc__notification-timer {
  background-color: white;
}

.medium-card-svg {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  left: 0;
}

.medium-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: 0.5s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
  cursor: pointer;
  position: relative;
  height: 105%;
}

.medium-card:hover {
  scale: 1.05;
}

.medium-img {
  position: relative;
  min-height: 13.3125rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
}

.medium-img img {
  width: 90%;
  margin: 5%;
  border-radius: 0.625rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.article-avatar img {
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  margin: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  flex-direction: column-reverse;
}

.article-avatar {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.article-header {
  margin-top: 20%;
}

.medium-card p {
  padding: 10px 30px;
  line-height: 1.6;
}

.content {
  margin-top: 10%;
}

.centered {
  display: flex;
  width: 100%;
  justify-content: center;
}

.about-text{
  margin-top: 20%;
  color: rgb(75, 72, 67);
}

@import url('https://fonts.googleapis.com/css?family=Hind');

:root {
  --radius-1: 70%;
  --radius-2: 30%;
  --radius-3: 30%;
  --radius-4: 70%;
  --radius-5: 60%;
  --radius-6: 40%;
  --radius-7: 60%;
  --radius-8: 40%;
  --danger: #c65b54 !important;
  --danger-dark: #c65b54 !important;
  --default: #7aa17e;
  --default-dark: #688a6b;

}

body {
  background: #e8e8e8;
  font: normal 18px/1 "Hind", sans-serif;
}

.medium-container {
  width: 30%;
  margin: 0 auto;
  padding-top: 7%;
}

#blob-container {
  width: 25vw;
  height: 25vw;
  position: relative;
}

#blob-picture {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: var(--radius-1) var(--radius-2) var(--radius-3) var(--radius-4) / var(--radius-5) var(--radius-6) var(--radius-7) var(--radius-8);
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #ff681c 0%, #755250 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

#profile-picture {
  width: 100%;
  height: auto;
  position: absolute;
  border-radius: var(--radius-1) var(--radius-2) var(--radius-3) var(--radius-4) / var(--radius-5) var(--radius-6) var(--radius-7) var(--radius-8);
  z-index: 2;
  top: -22%;
  left: 0;
  box-shadow: 0px 4px 0px 0px rgb(208, 96, 51);
}

.about {
  margin-top: 20%;
}

.p-about {
  margin: 0 20%;
  line-height: 1.5;
  margin-top: 3%;
}

#contact-me-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px auto;
  width: 8vw;
  height: 7vh;
  border-radius: 75px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: lighter;
  letter-spacing: 2px;
  transition: 1s box-shadow;
  background: rgb(255, 242, 227);
}

.nav-link:hover {
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, .1);
}

.nav-link:hover::before,
.nav-link:hover::after {
  display: block;
  content: '';
  position: absolute;
  width: 8vw;
  height: 7vh;
  background: #755250;
  border-radius: 75px;
  z-index: -1;
  animation: 1s clockwise infinite;
}

.nav-link:hover:after {
  background: #ff681c;
  animation: 2s counterclockwise infinite;
}

@keyframes clockwise {
  0% {
    top: -3px;
    left: 0;
  }

  12% {
    top: -2px;
    left: 2px;
  }

  25% {
    top: 0;
    left: 5px;
  }

  37% {
    top: 2px;
    left: 2px;
  }

  50% {
    top: 3px;
    left: 0;
  }

  62% {
    top: 2px;
    left: -2px;
  }

  75% {
    top: 0;
    left: -5px;
  }

  87% {
    top: -2px;
    left: -2px;
  }

  100% {
    top: -3px;
    left: 0;
  }
}

@keyframes counterclockwise {
  0% {
    top: -3px;
    right: 0;
  }

  12% {
    top: -2px;
    right: 2px;
  }

  25% {
    top: 0;
    right: 5px;
  }

  37% {
    top: 2px;
    right: 2px;
  }

  50% {
    top: 3px;
    right: 0;
  }

  62% {
    top: 2px;
    right: -2px;
  }

  75% {
    top: 0;
    right: -5px;
  }

  87% {
    top: -2px;
    right: -2px;
  }

  100% {
    top: -3px;
    right: 0;
  }
}

.social {
  margin: 20% 0%;
}

.social .row {
  display: flex;
  justify-content: center;
} 

input,
textarea {
  border: none;
  display: block;
  background: transparent;
  height: 40px;
  background: transparent;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 14px;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

textarea:focus,
input:focus {
  outline: none;
  border-bottom: 1px solid #774329;
}

textarea:focus::placeholder,
input:focus::placeholder {
  color: transparent;
}

textarea {
  height: 30%;
  resize: none;
}

.mail-img {
  min-width: 100%;
  height: auto;
  border-radius: 0 20px 20px 0;
}

.social-header {
  margin-bottom: 7%;
}

.contact {
  width: 75vw;
}

.contact-area {
  background: white;
  margin: 0px 5px;
  border-radius: 20px;
}

.sendbutton {
  background: #f28f60;
  color: white !important;
  border-radius: 15px;
  height: 3em;
  width: 40%;
  line-height: unset;
  text-align: center;
  cursor: pointer;
  margin-top: 18px;
}

.sendbutton:disabled {
  background-color: #cccccc;
  color: #666666;
}


#contact-me-form {
  text-align: center;
}

.footer{
  text-align: center;
  margin-top: 15%;
  margin-bottom: 0;
  color: rgb(75, 72, 67);
}

@media screen and (max-width: 768px) {
  .blob {
    width: 120px;
    height: 120px;
  }

  .profile {
    max-height: 120px;
  }

  text-area {
    height: 20px;
  }

  .mail-img {
    overflow: hidden;
    border-radius: 0px 0px 20px 20px;
    object-fit: cover;
    height: 30vh;
  }

  .contact-me-form {
    width: 50vh !important;
  }

  .contact-me {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact {
    width: 90vw;
  }

  .medium-container {
    width: 90%;
    margin-top: 10%;
  }

  .nav-link,
  .nav-link:hover,
  .nav-link:hover::before,
  .nav-link:hover::after {
    animation: unset;
    box-shadow: unset;
    transition: unset;
    width: auto;
    height: auto;
  }

  textarea {
    height: 7em;
    margin: 1em;
  }

  input{
    height: 2.4em;
    margin: 1em;
   }


  #blob-container {
    width: 31vw;
    height: 31vw;
  }

  #profile-picture {
    top: -13%;
  }

  #blob-picture{
    top: 11px;
  }

  .sendbutton {
    line-height: unset;
  }

  .p-about{
    margin: 10% 3%;
  }

  .about {
    margin-top: 46%;
}

  .sendbutton {
    height: 2em;
    line-height: unset;
    margin-top: 1em;
  }
}